import React from "react"
import styled from "@emotion/styled";
import {Spin, Typography} from "antd";


const FullPage = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FullPageLoading = () => {
    return <FullPage>
        <Spin/>
    </FullPage>
}

export const FullPageErrorFallback = ({error}: {error: Error | null}) => {
    return <FullPage>
        <ErrorBox error={error}/>
    </FullPage>
}

// eslint-disable-next-line
const isError = (value:any): value is Error => value?.message;

export const ErrorBox = ({error}: {error: unknown}) => {
    if (isError(error)) {
        return <Typography.Text type={"danger"}>{error.message}</Typography.Text>
    }
    return null
}

