import {useRef} from "react";


export default function useMediaRecorder() {
    const mediaStream = useRef<MediaStream>()
    const mediaRecorder = useRef<MediaRecorder>()
    const mediaBlobs = useRef<Blob[]>([])

    const startRecord = () => {
        navigator.mediaDevices.getUserMedia({audio: {channelCount: 1, sampleRate: 16000}, video: false}).then(
            m_stream => {
                mediaStream.current = m_stream
                mediaRecorder.current = new MediaRecorder(mediaStream.current)
                mediaRecorder.current?.start()
                mediaRecorder.current.ondataavailable = (blobEvent) => {
                    if (blobEvent.data.size > 0) {
                        mediaBlobs.current.push(blobEvent.data)
                    }
                }
            }
        )
    }

    const stopRecord = () => {
        mediaRecorder.current?.stop()
    }

    const composeRecord = () => {
        mediaStream.current?.getTracks().forEach((track) => track.stop())
        mediaBlobs.current = []
    }

    const pauseRecord = () => {
        mediaRecorder.current?.pause()
    }

    const resumeRecord = () => {
        mediaRecorder.current?.resume()
    }

    return {
        mediaBlobs, mediaRecorder, startRecord, stopRecord, resumeRecord, pauseRecord, composeRecord
    }
}

