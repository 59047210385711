import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import { Message, MsgContentType, MsgType } from "../../types/chat"
import styled from "@emotion/styled"
import Avatar from "assets/WZ.JPG"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import "photoswipe/dist/photoswipe.css"
import { Gallery, Item } from "react-photoswipe-gallery"
import { CopyOutlined, DownCircleOutlined, LinkOutlined } from "@ant-design/icons"
import { Space, theme, List } from "antd"
import { GlobalToken } from "antd/es/theme/interface"
import remarkGfm from "remark-gfm"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { a11yDark, prism } from "react-syntax-highlighter/dist/esm/styles/prism"
import { useTheme } from "../../context/theme-context"
import { IconButton } from "utils/public-css"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useAuth } from "../../context/auth-context"

interface Props {
	msgList: Message[]
}

const DefaultAskUser = {
	username: "用户",
	avatar: Avatar,
}

const DefaultAnswerUser = {
	username: "问知科技",
	avatar: Avatar,
}

export default function ChatList({ msgList }: Props) {
	const [showDownCircle, setShowDownCircle] = useState(false)
	const chatListRef = useRef<HTMLDivElement>(null)

	const handleScroll = () => {
		if (chatListRef.current && chatListRef.current.scrollTop < 0) {
			setShowDownCircle(true)
		} else {
			setShowDownCircle(false)
		}
	}

	const handleScrollDown = () => {
		chatListRef.current?.scrollTo({ left: 0, top: 0, behavior: "smooth" })
	}

	useEffect(() => {
		chatListRef.current?.addEventListener("scroll", handleScroll)
	}, [])

	return (
		<Container>
			<ChatListStyled ref={chatListRef}>
				{msgList.map((msg, index) => {
					return <MessageBubble msg={msg} key={index} />
				})}
			</ChatListStyled>
			{showDownCircle && <DownCircle onClick={handleScrollDown} />}
		</Container>
	)
}

interface MessageBubbleProps {
	msg: Message
}

function MessageBubble({ msg }: MessageBubbleProps) {
	const { token } = theme.useToken()
	const { user: loginUser } = useAuth()

	const isSelf = msg.type === MsgType.Ask

	const getUser = (msg: Message) => {
		if (!msg.payload.user) {
			if (isSelf) {
				return loginUser ? loginUser : DefaultAskUser
			} else {
				return DefaultAnswerUser
			}
		}
		return msg.payload.user
	}
	const user = getUser(msg)
	return (
		<MessageWrapper isSelf={isSelf}>
			<MessageStyled isSelf={isSelf}>
				<img src={user.avatar} alt={user.username} />
				<MessageBubbleStyled isSelf={isSelf} token={token}>
					<span style={{ color: token.colorTextTertiary }}>{user.username}</span>
					<MessageContentTheme msg={msg} />
				</MessageBubbleStyled>
			</MessageStyled>
		</MessageWrapper>
	)
}

export function MD({ content }: { content: string }) {
	const { themeState } = useTheme()
	const { token } = theme.useToken()

	interface RenderImageProps {
		// node: ReactMarkdownProps["node"];
		src: string | undefined
	}

	const renderImage = ({ src }: RenderImageProps) => {
		const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

		useEffect(() => {
			const img = new Image()
			img.src = src as string

			img.onload = () => {
				const { width, height } = img
				setImageSize({ width, height })
			}
		}, [src])

		return (
			<Item original={src} thumbnail={src} width={imageSize.width} height={imageSize.height}>
				{({ ref, open }) => (
					<img
						ref={ref as MutableRefObject<HTMLImageElement>}
						onClick={open}
						src={src}
						style={{ width: "30%", height: "auto" }}
					/>
				)}
			</Item>
		)
	}

	interface RenderCodeProps {
		inline: boolean | undefined
		className: string | undefined
		children: React.ReactNode & React.ReactNode[]
	}

	// a11yDark prism
	const codeStyle =
		themeState.custom.codeStyle === "prism"
			? prism
			: themeState.custom.codeStyle === "a11yDark"
			? a11yDark
			: undefined
	const renderCode = ({ inline, className, children }: RenderCodeProps) => {
		const [copied, setCopied] = useState(false)

		const codeText = useMemo(() => String(children), [children])

		useEffect(() => {
			if (copied) {
				setTimeout(() => {
					setCopied(false)
				}, 1000)
			}
		}, [copied])

		const match = /language-(\w+)/.exec(className || "")
		return (
			<Space direction="vertical" size={0} style={{ width: "100%" }}>
				{!inline && match && (
					<CodeTitle style={{ backgroundColor: token.colorTextQuaternary }}>
						{match && match[1]}
						<CopyToClipboard text={codeText} onCopy={() => setCopied(true)}>
							<IconButton>
								<CopyOutlined />
								{copied ? "Copied" : "Copy"}
							</IconButton>
						</CopyToClipboard>
					</CodeTitle>
				)}
				{!inline && match ? (
					<SyntaxHighlighter style={codeStyle} language={match[1]} PreTag="div" customStyle={{ margin: 0 }}>
						{codeText}
					</SyntaxHighlighter>
				) : (
					<code className={className} style={{ margin: 0 }}>
						{children}
					</code>
				)}
			</Space>
		)
	}

	return (
		<Gallery>
			<ReactMarkdown
				rehypePlugins={[rehypeRaw]}
				remarkPlugins={[[remarkGfm, { tableCellPadding: "10px" }]]}
				components={{
					img: ({ src }) => renderImage({ src }),
					code: ({ inline, className, children }) => renderCode({ inline, className, children }),
					li: ({ children }) => <li style={{ marginLeft: "1.5rem" }}>{children}</li>,
				}}
			>
				{content}
			</ReactMarkdown>
		</Gallery>
	)
}

function MessageContentTheme({ msg }: MessageBubbleProps) {
	if (msg.payload.msgContent.error) {
		return <MessageContentErrorStyled>{msg.payload.msgContent.error.message}</MessageContentErrorStyled>
	}
	const msgType = msg.payload.msgContent.msgType
	switch (msgType) {
		case MsgContentType.Text:
			return <div>{msg.payload.msgContent.data.content}</div>
		case MsgContentType.MarkDown:
			return <MD content={msg.payload.msgContent.data.content} />
		case MsgContentType.OCR:
			return (
				<>
					<List>
						{msg.payload.msgContent.data.ocr.map((item, index) => {
							return (
								<List.Item key={index}>
									<LinkOutlined />
									{item.filename}
								</List.Item>
							)
						})}
					</List>
					<MD content={msg.payload.msgContent.data.content} />
				</>
			)
		default:
			return null
	}
}

const Container = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 70%;
`

const ChatListStyled = styled.div`
	display: flex;
	flex-direction: column-reverse;
	overflow: auto;
	width: 100%;
	height: 100%;

	::-webkit-scrollbar {
		width: 0.6rem;
	}

	::-webkit-scrollbar-thumb {
		background-color: #c1c1c1;
	}
`

const MessageWrapper = styled.div<{ isSelf: boolean }>`
	display: flex;
	justify-content: ${(props) => (props.isSelf ? "flex-end" : "flex-start")};
`

const MessageStyled = styled.div<{ isSelf: boolean }>`
	display: flex;
	flex-direction: ${(props) => (props.isSelf ? "row-reverse" : "row")};
	max-width: 70%;
	margin: 1rem 1.5rem 0.3rem;

	img {
		width: 3rem;
		height: 3rem;
		border-radius: 1.6rem;
	}
`

const MessageBubbleStyled = styled.div<{ isSelf: boolean; token: GlobalToken }>`
	display: flex;
	flex-direction: column;
	margin: 1rem;
	padding: 1rem 1rem;
	border-radius: 1rem;
	background-color: ${(props) => (props.isSelf ? props.token.colorPrimary : props.token.colorBgBase)};
	overflow: auto;
	line-height: 1.5;

	span {
		font-size: 1rem;
		padding-bottom: 0.5rem;
	}

	div {
		white-space: pre-wrap;
		word-wrap: break-word;
	}
`

const MessageContentErrorStyled = styled.div`
	color: red;
`

const DownCircle = styled(DownCircleOutlined)`
	position: absolute;
	bottom: 3%;
	right: 5%;
	font-size: 2rem;
	color: hsl(0, 0%, 50%);
	animation: fade-in 0.5s ease-in-out;
	@keyframes fade-in {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`
const CodeTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 1rem 1rem 0 0;
	height: 3rem;
	padding: 0 1.5rem 0 1.5rem;
`
