import React, { useEffect, useRef, useState } from "react"
import ChatInput from "./ChatInput"
import {
	AskData,
	getDefaultReplyMsg,
	getDefaultSendMessage,
	Message,
	MsgContentType,
	msgListToApiMsg,
	MsgType,
} from "../../types/chat"
import styled from "@emotion/styled"
import ChatList from "./ChatList"
import useWS, { wsReadyState } from "../../hooks/ws"
import { FullPageErrorFallback } from "../FullPage"
import { v4 as uuid } from "uuid"
import { produce } from "immer"
import { Divider, theme } from "antd"
import { BoxHeader } from "./ChatBoxHeader"
import { options, switchModel } from "./SwitchModel"
import { clearMsg, getChatRecord } from "../../service/chat"
import { ChatRecordToMessage } from "./util"
import { useAuth } from "../../context/auth-context"

export interface InputMsg {
	content: string
	ocr: object[]
	type: MsgContentType
}

export default function ChatBox() {
	const [msgList, setMsgList] = useState<Message[]>([])
	const [msg, setMsg] = useState("")
	const [sending, setSending] = useState(false)
	const [model, setModel] = useState<switchModel>(switchModel.XH)
	const reConnCount = useRef(0)
	const [err, setErr] = useState<Error | null>(null)
	const visitorId = useRef(uuid().substring(0, 8))
	const stopRef = useRef(new Map<string, boolean>())
	const { token } = theme.useToken()
	const { user } = useAuth()

	const UserId = user ? user.id : visitorId.current
	const MAX_RE_CONN_COUNT = 3 // 重连次数

	const { wsMessage, wsState, reconnectWS, sendMsgWS, setWsMessage } = useWS(
		process.env.REACT_APP_WS_URL || "",
		"chat",
	)

	const curModel = options.find((item) => item.value === model)

	// 切换模型
	const handleChangeModel = (switch_model: switchModel) => {
		setModel(switch_model)
		setMsgList([])
	}

	// 消息清理
	const clearMessageList = () => {
		if (msgList.length > 0) {
			const askId = msgList[0].payload.msgContent.data.askId
			clearMsg(askId).then(() => {
				setMsgList([])
			})
		}
	}

	const sendMsg = (m: InputMsg) => {
		const sendData = getDefaultSendMessage(m) as Message
		const replyData = getDefaultReplyMsg(sendData.payload.msgContent.data.askId) as Message
		setSending(true)
		setMsgList([replyData, sendData, ...msgList])
		sendMsgWS(
			JSON.stringify({
				...sendData.payload.msgContent.data,
				userId: UserId,
				type: MsgType.Ask,
				model: model,
				history: msgListToApiMsg(msgList.slice(-6).reverse()),
			} as unknown as AskData),
		)
		setMsg("")
	}

	// 追加消息
	const answerMsg = (r_m: Message) => {
		const r_m_data = r_m.payload.msgContent.data
		if (r_m_data.index === -1) {
			// 回复完毕后关闭loading
			setSending(false)
		}
		setMsgList(
			produce((draft) => {
				draft.map((item) => {
					if (item.payload.msgContent.data.askId === r_m_data.askId && item.type === MsgType.Answer) {
						item.payload.msgContent.data.content += r_m_data.content // 直接在原始对象上进行修改
						item.payload.msgContent.error = r_m.payload.msgContent.error
						item.payload.msgContent.msgType = r_m.payload.msgContent.msgType
					}
					return item
				})
			}),
		)
	}

	useEffect(() => {
		const cpWsMag = [...wsMessage]
		if (cpWsMag.length !== 0) {
			// 吞字解决方案
			setWsMessage((v) => v.filter((val) => !cpWsMag.includes(val)))
		}
		cpWsMag.map((item) => {
			// 处理收到的消息
			if (item.trim() !== "") {
				const r_m = JSON.parse(item) as Message
				if (r_m.type === MsgType.Answer && !stopRef.current.has(r_m.payload.msgContent.data.askId)) {
					answerMsg(r_m)
				}
			}
		})
	}, [wsMessage])

	// 重连逻辑
	useEffect(() => {
		const interval_id = setInterval(() => {
			if (wsState === wsReadyState.Closed && reConnCount.current < MAX_RE_CONN_COUNT) {
				reconnectWS()
				setSending(false)
				reConnCount.current += 1
			} else if (wsState === wsReadyState.Ready) {
				reConnCount.current = 0
			} else if (reConnCount.current === MAX_RE_CONN_COUNT) {
				reConnCount.current += 1
				setErr(Error("网络异常 请刷新页面"))
			}
		}, 3000)
		return () => {
			clearInterval(interval_id)
		}
	}, [wsState])

	// 聊天记录
	useEffect(() => {
		getChatRecord(model, UserId.toString()).then((data) => {
			const result = ChatRecordToMessage(data.data.result)
			setMsgList(result)
		})
	}, [model])

	const inputMsg = (value: string) => {
		setMsg(value)
	}

	// const stopMsg = (askId: string) => {
	//     stopRef.current.set(askId, true)
	//     setSending(false)
	// }

	if (err) {
		return <FullPageErrorFallback error={err} />
	}

	return (
		<ChatBoxStyled style={{ backgroundColor: token.colorFillSecondary }}>
			<BoxHeader
				model={model}
				userId={UserId}
				changeModel={handleChangeModel}
				clearMessageList={clearMessageList}
			/>
			<Divider style={{ margin: "1rem 0" }} />
			<ChatList msgList={msgList} />
			<ChatInput model={curModel} msg={msg} sendMsg={sendMsg} inputMsg={inputMsg} loading={sending}></ChatInput>
		</ChatBoxStyled>
	)
}

const ChatBoxStyled = styled.div`
	display: flex;
	width: 80%;
	height: 80vh;
	margin: 0 0 0 10%;
	flex-direction: column;
	//justify-content: center;
	align-items: center;
	background-color: var(--primary-backgroud-color);
	border-radius: 3rem;
`

// 深层更新
// setMsgList(m => m.map(item => {
//     if (item.payload.msgContent.data.askId === r_m_data.askId && item.type === MsgType.Answer){
//         return {...item, payload: {...item.payload, msgContent: {...item.payload.msgContent, data: {
//             ...item.payload.msgContent.data, content: item.payload.msgContent.data.content + r_m_data.content
//                     }}}}
//     } else {
//         return item
//     }
// }))
