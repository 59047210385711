import "./wdyr"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ConfigProvider } from "antd"
import ThemeProvider, { useTheme } from "./context/theme-context"
import { AuthProvider } from "./context/auth-context"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const AppWrapper = () => {
	const { themeState } = useTheme()
	return (
		<ConfigProvider theme={themeState.antd}>
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.REACT_APP_RECAPTCHAKEY as string}
				language={"zh-CN"}
				useRecaptchaNet={true}
			>
				<AuthProvider>
					<App />
				</AuthProvider>
			</GoogleReCaptchaProvider>
		</ConfigProvider>
	)
}

const root = ReactDOM.createRoot(document.getElementById("root") as Element)
root.render(
	<React.StrictMode>
		<ThemeProvider>
			<AppWrapper />
		</ThemeProvider>
	</React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
