import instance from "../utils/http"
import { ApiResponse } from "./util"

type UploadRes = {
	url: string
}

export const UploadApi = (file: Blob): Promise<ApiResponse<UploadRes>> => {
	const formData = new FormData()
	formData.append("file", file)
	return instance.post("v1/upload/", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	})
}

export const UploadKimi = (file: File): Promise<ApiResponse<{ content: string }>> => {
	const formData = new FormData()
	formData.append("file", file)
	return instance.post("v1/kimi_upload/", formData, {
		timeout: 60000,
		headers: {
			"Content-Type": "multipart/form-data",
		},
	})
}
