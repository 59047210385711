import React, {createContext, useContext, useReducer} from "react"
import ThemeReducer, {Action, CustomThemeConfig} from "./theme-reducer";
import {theme} from "antd";


export const DarkTheme: CustomThemeConfig = {
    antd: {
        token: {
            colorPrimary: "#00b96b",
            fontFamily: "'SourceHanSansSC-VF', 'Helvetica Neue', sans-serif",
        },
        algorithm: theme.darkAlgorithm,
    },
    custom: {
        codeStyle: "a11yDark"
    }
}

export const LightTheme: CustomThemeConfig = {
    antd: {
        token: {
            colorPrimary: "#00b96a",
            fontFamily: "'SourceHanSansSC-VF', 'Helvetica Neue', sans-serif",
        },
        algorithm: theme.defaultAlgorithm
    },
    custom: {
        codeStyle: "prism"
    }
}

type ContextProps = {
    themeState: CustomThemeConfig
    themeDispatch: React.Dispatch<Action>
}


export const ThemeContext = createContext<ContextProps>({
    themeState: {antd: {}, custom: {}},
    themeDispatch: () => null
});

export default function ThemeProvider({children}: {children: React.ReactNode}) {
    const [themeState, themeDispatch] = useReducer(ThemeReducer, LightTheme)

    return (
        <ThemeContext.Provider value={{themeState, themeDispatch}}>
            {children}
        </ThemeContext.Provider>
    )
}

export function useTheme() {
    return useContext(ThemeContext)
}
