import React from "react";
import Nav from "../components/nav/nav";
import ChatBox from "../components/chat/ChatBox";
import ThemeSwitch from "../components/theme-switch";
import {Layout, theme} from "antd";
import {Content, Footer} from "antd/es/layout/layout";

export default function Home() {
    const { token } = theme.useToken()
    return <Layout style={{maxWidth: "100%"}}>
            <Nav/>
            <Content>
                <ChatBox/>
                <ThemeSwitch/>
            </Content>
            <Footer style={{ textAlign: 'center', color: token.colorTextTertiary }}>© 2019-2023</Footer>
        </Layout>
}

