import {
	Button,
	Card,
	Divider,
	Input,
	message,
	Modal,
	Popconfirm,
	Row,
	Steps,
	Upload,
	UploadFile,
	UploadProps,
} from "antd"
import ImgCrop from "antd-img-crop"
import React, { useEffect, useRef, useState } from "react"
import { RcFile, UploadChangeParam } from "antd/es/upload"
import { toast } from "react-toastify"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { useAuth } from "../../context/auth-context"
import { UploadApi } from "../../service/pubilc"
import {
	ChangeMobile,
	DelAccountApi,
	resetPassword,
	userUpdate,
	weChatLoginQR,
	weChatLoginQRState,
} from "../../service/user"
import { v4 } from "uuid"
import { ResetPasswordForm, SceneType } from "../../types/user"
import QRCode from "qrcode.react"
import { ResetPasswordCard } from "../login/reset-password"
import { SendMobileCode } from "../login/component"
import { ProForm } from "@ant-design/pro-components"

const beforeUpload = (file: RcFile) => {
	const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
	if (!isJpgOrPng) {
		toast.error("You can only upload JPG/PNG file!")
	}
	const isLt2M = file.size / 1024 / 1024 < 2
	if (!isLt2M) {
		toast.error("Image must smaller than 2MB!")
	}
	return isJpgOrPng && isLt2M
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
	const reader = new FileReader()
	reader.addEventListener("load", () => callback(reader.result as string))
	reader.readAsDataURL(img)
}

export function AvatarModal() {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [imageUrl, setImageUrl] = useState<string>()
	const [loading, setLoading] = useState(false)
	const { user, setUser } = useAuth()

	const handleModal = () => {
		setIsModalOpen(!isModalOpen)
	}

	const handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile>) => {
		if (info.file.status === "uploading") {
			setLoading(true)
			return
		}
		if (info.file.status === "done") {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj as RcFile, (url) => {
				setLoading(false)
				setImageUrl(url)
			})
		}
	}

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	)

	const uploadHandle = async ({ file }: { file: Blob | string | RcFile }) => {
		UploadApi(file as Blob).then((res) => setImageUrl(res.data.url))
	}

	const updateAvatar = () => {
		user &&
			userUpdate(user.id, { avatar: imageUrl }).then((res) => {
				setUser(res.data)
				handleModal()
			})
	}

	return (
		<>
			<Button onClick={handleModal}>修改头像</Button>
			<Modal
				title={"修改头像"}
				open={isModalOpen}
				onCancel={handleModal}
				footer={imageUrl ? undefined : null}
				centered={true}
				cancelText={"取消"}
				okText={"提交"}
				onOk={updateAvatar}
			>
				<Divider />
				<div style={{ textAlign: "center" }}>
					<ImgCrop rotationSlider={true} modalOk={"确认"} modalCancel={"取消"}>
						<Upload
							name={"avatar"}
							listType={"picture-circle"}
							showUploadList={false}
							beforeUpload={beforeUpload}
							onChange={handleChange}
							customRequest={uploadHandle}
						>
							{imageUrl ? (
								<img src={imageUrl} alt="avatar" style={{ width: "100%", borderRadius: "50%" }} />
							) : (
								uploadButton
							)}
						</Upload>
					</ImgCrop>
				</div>
			</Modal>
		</>
	)
}

export function UsernameModal() {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [username, setUsername] = useState("")
	const { user, setUser } = useAuth()

	useEffect(() => {
		setUsername(user?.username || "")
	}, [])

	const handleModal = () => {
		setIsModalOpen(!isModalOpen)
	}

	const updateUsername = () => {
		user &&
			userUpdate(user.id, { username: username })
				.then((res) => {
					setUser(res.data)
					handleModal()
				})
				.catch(() => {
					return
				})
	}

	return (
		<>
			<Button onClick={handleModal}>编辑用户名</Button>
			<Modal
				title={"编辑用户名"}
				open={isModalOpen}
				onCancel={handleModal}
				centered={true}
				cancelText={"取消"}
				okText={"提交"}
				onOk={updateUsername}
			>
				<Divider />
				<Input value={username} onChange={(e) => setUsername(e.target.value)} />
			</Modal>
		</>
	)
}

export function UnavailableModal({ buttonText, danger }: { buttonText: string; danger: boolean }) {
	const warning = () => {
		Modal.warning({
			title: "提示",
			content: `${buttonText}暂不可用`,
		})
	}
	return (
		<Button onClick={warning} danger={danger}>
			{buttonText}
		</Button>
	)
}

export function WeChatModal({ isBound }: { isBound: boolean }) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [qr, setQr] = useState("")
	const track_id = useRef(v4())

	const handleModal = () => {
		setIsModalOpen(!isModalOpen)
		setQr("")
	}

	useEffect(() => {
		if (isModalOpen) {
			const type = isBound ? SceneType.UnBind : SceneType.Bind
			weChatLoginQR(track_id.current, type).then((res) => {
				setQr(res.data.url)
			})
		}
	}, [isModalOpen])

	useEffect(() => {
		const type = isBound ? SceneType.UnBind : SceneType.Bind

		const interval_id = setInterval(() => {
			if (qr !== "") {
				weChatLoginQRState(track_id.current, type).then((res) => {
					if (isBound && !res.data.is_bound) {
						handleModal()
						toast.info("解绑成功")
						location.reload()
					} else if (!isBound && res.data.is_bound) {
						handleModal()
						toast.success("绑定成功")
						location.reload()
					}
				})
			}
		}, 1000)
		return () => clearInterval(interval_id)
	}, [qr])

	return (
		<>
			<Button onClick={handleModal} danger={isBound}>
				{isBound ? "解绑" : "绑定"}
			</Button>
			<Modal
				title={isBound ? "解绑微信" : "绑定微信"}
				open={isModalOpen}
				centered={true}
				onCancel={handleModal}
				footer={null}
			>
				<Row justify={"center"}>
					{qr && (
						<QRCode
							value={qr}
							size={128}
							bgColor={"#ffffff"}
							fgColor={"#4e4141"}
							id={"qrcode-canvas"}
							level={"L"}
							includeMargin={true}
						/>
					)}
				</Row>
			</Modal>
		</>
	)
}

export function PasswordModal({ isSetup, mobile }: { isSetup: boolean; mobile: string }) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [form] = ProForm.useForm()

	form.setFieldValue("mobile", mobile)

	const handleModal = () => {
		setIsModalOpen(!isModalOpen)
	}

	const submitHandle = async (values: unknown): Promise<void> => {
		resetPassword(values as ResetPasswordForm).then(() => {
			if (isSetup) {
				message.success("重置密码成功！")
			} else {
				message.success("设置密码成功！")
			}
			location.reload()
		})
	}

	return (
		<>
			<Button onClick={handleModal} danger={isSetup}>
				{isSetup ? "修改密码" : "设置密码"}
			</Button>
			<Modal
				title={isSetup ? "修改密码" : "设置密码"}
				open={isModalOpen}
				centered={true}
				onCancel={handleModal}
				footer={null}
			>
				<ResetPasswordCard submitHandle={submitHandle} form={form} />
			</Modal>
		</>
	)
}

const steps = [
	{
		title: "当前手机",
		content: <SendMobileCode source={5} key={0} />,
	},
	{
		title: "更换手机",
		content: <SendMobileCode source={5} key={1} />,
	},
]

export function ChangeMobileModal({ mobile }: { mobile: string }) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [current, setCurrent] = useState(0)
	const [oldForm] = ProForm.useForm()
	const [curForm] = ProForm.useForm()

	oldForm.setFieldValue("mobile", mobile)

	const items = steps.map((item) => ({ key: item.title, title: item.title }))

	const handleModal = () => {
		setIsModalOpen(!isModalOpen)
	}

	const next = () => {
		oldForm
			.validateFields()
			.then(() => {
				setCurrent(current + 1)
			})
			.catch(() => {
				console.log("验证失败")
			})
	}

	const prev = () => {
		curForm
			.validateFields()
			.then(() => {
				setCurrent(current - 1)
			})
			.catch(() => {
				console.log("验证失败")
			})
	}

	const submitHandle = () => {
		ChangeMobile({
			old_mobile: oldForm.getFieldValue("mobile"),
			old_code: oldForm.getFieldValue("code"),
			cur_mobile: curForm.getFieldValue("mobile"),
			cur_code: curForm.getFieldValue("code"),
		}).then(() => {
			message.success("手机号更换成功")
			location.reload()
		})
	}

	return (
		<>
			<Button onClick={handleModal} danger={true}>
				更换手机
			</Button>

			<Modal title={"更换手机"} open={isModalOpen} centered={true} onCancel={handleModal} footer={null}>
				<Steps current={current} items={items}></Steps>
				<Card bordered={false} style={{ boxShadow: "none" }}>
					<ProForm submitter={false} key={current} form={current == 0 ? oldForm : curForm}>
						{steps[current].content}
					</ProForm>
				</Card>
				<div>
					{current < steps.length - 1 && (
						<Button type="primary" onClick={() => next()}>
							下一个
						</Button>
					)}
					{current === steps.length - 1 && (
						<Button type="primary" onClick={submitHandle}>
							完成
						</Button>
					)}
					{current > 0 && (
						<Button style={{ margin: "0 8px" }} onClick={() => prev()}>
							上一个
						</Button>
					)}
				</div>
			</Modal>
		</>
	)
}

export function DelAccount({ mobile }: { mobile: string }) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [form] = ProForm.useForm()
	const { logout } = useAuth()

	form.setFieldValue("mobile", mobile)

	const handleModal = () => {
		setIsModalOpen(!isModalOpen)
	}

	const submitHandle = () => {
		DelAccountApi({
			mobile: form.getFieldValue("mobile"),
			code: form.getFieldValue("code"),
		}).then(() => logout())
	}

	return (
		<>
			<Button onClick={handleModal} danger={true}>
				注销
			</Button>
			<Modal open={isModalOpen} title="注销账号" onCancel={handleModal} centered={true} footer={null}>
				<ProForm form={form} submitter={false}>
					<SendMobileCode source={6} />
				</ProForm>
				<Popconfirm
					title={"确定注销该账号吗？"}
					okText={"确定"}
					cancelText={"返回"}
					onConfirm={() => {
						submitHandle()
					}}
				>
					<Button type={"primary"}>提交</Button>
				</Popconfirm>
			</Modal>
		</>
	)
}
