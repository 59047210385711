import React, { useCallback, useEffect } from "react";
import useAsync from "../hooks/async";
import { AuthForm, RegisterForm, User } from "../types/user";
import * as auth from "../utils/auth-provider";
import { FullPageErrorFallback, FullPageLoading } from "../components/FullPage";
import { getUserInfo } from "../service/user";
import { getLocalUserInfo, user_info } from "../utils/auth-provider";

const bootstrapUser = async () => {
  const token = auth.getToken();
  if (token) {
    return getUserInfo()
      .then((data) => {
        // 单点登录同步用户信息 getUserInfo()可替换为user_info
        if (token.accessToken && token.expires > new Date().getTime()) {
          const userInfo = getLocalUserInfo();
          if (!userInfo || userInfo.expires != token.expires) {
            user_info();
          }
        }
        return data.data;
      })
      .catch(() => {
        return null;
      });
  }
  return null;
};

const AuthContext = React.createContext<
  | {
      user: User["user_info"] | null;
      login: (form: AuthForm) => Promise<void>;
      register: (form: RegisterForm) => Promise<void>;
      logout: () => Promise<void>;
      refresh_token: () => Promise<void>;
      setUser: (user: User["user_info"]) => void;
    }
  | undefined
>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    data: user,
    error,
    isError,
    isLoading,
    isIdle,
    run,
    setData: setUser,
  } = useAsync<User["user_info"] | null>();

  const login = (form: AuthForm) => auth.login(form).then(setUser);
  const register = (form: RegisterForm) => auth.register(form).then(setUser);
  const logout = () => auth.logout().then(() => setUser(null));
  const refresh_token = () => auth.refresh_token().then(setUser);

  const callBack = useCallback(() => {
    run(bootstrapUser());
  }, []);

  useEffect(() => {
    callBack();
  }, [callBack]);

  if (isError) {
    return <FullPageErrorFallback error={error} />;
  }

  if (isIdle || isLoading) {
    return <FullPageLoading />;
  }

  return (
    <AuthContext.Provider
      value={{ user, login, register, logout, refresh_token, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth必须在AuthProvider中使用");
  }
  return context;
};
