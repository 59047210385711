import { ProForm, ProFormCaptcha } from "@ant-design/pro-components"
import { Checkbox, Input, message, theme, Typography } from "antd"
import { LockOutlined, MobileOutlined } from "@ant-design/icons"
import { sendCode } from "../../service/user"
import React from "react"
import styled from "@emotion/styled"
import { LoginPageType } from "../../pages/login"
import { Link } from "react-router-dom"
import useGToken from "../../hooks/useGToken"

const { Text } = Typography

export function SendMobileCode({ source }: { source: LoginPageType }) {
	const { handleReCaptchaVerify } = useGToken("sendCode")

	return (
		<>
			<ProForm.Item
				name={"mobile"}
				rules={[
					{ required: true, message: "请输入手机号" },
					{
						pattern: /^1\d{10}$/,
						message: "手机号格式错误",
					},
				]}
			>
				<Input
					allowClear={true}
					maxLength={11}
					placeholder={"手机号"}
					size={"large"}
					prefix={<MobileOutlined className={"prefixIcon"} />}
				></Input>
			</ProForm.Item>
			<ProFormCaptcha
				fieldProps={{
					size: "large",
					prefix: <LockOutlined className={"prefixIcon"} />,
				}}
				captchaProps={{
					size: "large",
				}}
				placeholder={"请输入验证码"}
				captchaTextRender={(timing, count) => {
					if (timing) {
						return `${count} ${"获取验证码"}`
					}
					return "获取验证码"
				}}
				phoneName={"mobile"}
				name="code"
				rules={[
					{
						required: true,
						message: "请输入验证码！",
					},
				]}
				onGetCaptcha={async (phone) => {
					handleReCaptchaVerify()?.then((token) => {
						sendCode(phone, source, token).then(() => message.success("获取验证码成功！"))
					})
				}}
			/>
		</>
	)
}

export function Protocol() {
	const { token } = theme.useToken()
	return (
		<ProForm.Item valuePropName={"checked"} name={"protocol"} rules={[{ required: true, message: "请勾选" }]}>
			<Checkbox>
				<Text type={"secondary"}>
					我已阅读并同意
					<Link style={{ color: token.colorPrimary }} to={"/agreements/behavior/"}>
						《用户协议》
					</Link>
					与
					<Link style={{ color: token.colorPrimary }} to={"/agreements/privacy/"}>
						《隐私政策》
					</Link>
				</Text>
			</Checkbox>
		</ProForm.Item>
	)
}

export const StyledForm = styled(ProForm)`
	.ant-form-item {
		margin-bottom: 2rem;
	}
`

export function PDForm({ source }: { source: LoginPageType }) {
	return (
		<>
			<SendMobileCode source={source} />
			<ProForm.Item name={"password"} rules={[{ required: true, message: "请输入密码" }]}>
				<Input.Password
					maxLength={15}
					placeholder={"密码"}
					size={"large"}
					prefix={<LockOutlined className={"prefixIcon"} />}
				></Input.Password>
			</ProForm.Item>
			<ProForm.Item
				name={"confirmPassword"}
				rules={[
					{ required: true, message: "请输入密码" },
					({ getFieldValue }) => ({
						validator(_, value) {
							if (value !== getFieldValue("password")) {
								return Promise.reject(new Error("俩次密码输入不一致"))
							} else {
								return Promise.resolve()
							}
						},
					}),
				]}
			>
				<Input.Password
					maxLength={15}
					placeholder={"确认密码"}
					size={"large"}
					prefix={<LockOutlined className={"prefixIcon"} />}
				></Input.Password>
			</ProForm.Item>
		</>
	)
}
