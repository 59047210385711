import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { Col, Dropdown, MenuProps, Popover, theme, Tooltip, Typography, Upload } from "antd"
import { AudioOutlined, DeleteOutlined, SendOutlined, UploadOutlined } from "@ant-design/icons"
import { audioToText } from "../../service/chat"
import useMediaRecorder from "../../hooks/media-recorder"
import { IconButton } from "utils/public-css"
import { getWaveBlob } from "webm-to-wav-converter"
import { OptionType } from "./SwitchModel"
import { RcFile } from "antd/es/upload"
import { UploadKimi } from "../../service/pubilc"
import { InputMsg } from "./ChatBox"
import { MsgContentType } from "../../types/chat"

interface ChatInputProps {
	msg: string
	loading: boolean
	sendMsg: (msg_content: InputMsg) => void
	inputMsg: (value: string) => void
	model: OptionType | undefined
}

interface UploadFile {
	file: RcFile
	content: string
}

export default function ChatInput({ msg, sendMsg, inputMsg, loading, model }: ChatInputProps) {
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const { token } = theme.useToken()
	const [matchItems, setMatchItems] = useState<MenuProps["items"]>([])
	const [fileArr, setFileArr] = useState<UploadFile[]>([])

	const resetFileArr = (arr: UploadFile[]) => {
		setFileArr(arr)
	}

	const addFile = (file: UploadFile) => {
		setFileArr((prev) => [...prev, file])
	}

	const getInputMsg = () => {
		return {
			content: msg,
			ocr: fileArr.map((item) => JSON.parse(item.content)),
			type: fileArr.length > 0 ? MsgContentType.OCR : MsgContentType.Text,
		}
	}

	function sendHandle() {
		if (msg.trim() === "" || loading) {
			return
		}
		sendMsg(getInputMsg())
		setFileArr([])
		if (textareaRef.current) {
			textareaRef.current.style.height = "auto"
		}
	}

	function enterHandle(event: KeyboardEvent) {
		if (event.shiftKey && event.key === "Enter") {
			event.preventDefault()
			insertNewLine()
		} else if (event.key === "Enter") {
			event.preventDefault()
			sendHandle()
		}
	}

	type matchDataType = {
		content: string
	}

	function questionMatch(value: string): Array<matchDataType> {
		const question = ["你可以用来做什么", "今天过的怎么样", "今天中午吃什么", "今天晚上吃什么"]
		const matchData: Array<matchDataType> = []
		question.map((item) => {
			if (value && item.includes(value)) {
				matchData.push({ content: item })
			}
		})
		return matchData
	}

	// 匹配常见问题
	function promptQuestion(value: string) {
		if (value.length <= 10) {
			const matchData = questionMatch(value)
			setMatchItems(
				matchData.map((item) => {
					return { key: item.content, label: item.content }
				}),
			)
		}
	}

	const questionClick = ({ key }: { key: string }) => {
		setMatchItems([])
		inputMsg(key)
	}

	function changeHandle(event: ChangeEvent<HTMLTextAreaElement>) {
		const textarea = event.target
		textarea.style.height = "auto"
		textarea.style.height = textarea.scrollHeight + "px"
		inputMsg(textarea.value)
		promptQuestion(textarea.value)
	}

	useEffect(() => {
		textareaRef.current?.focus()
	}, [])

	const insertNewLine = () => {
		const textarea = textareaRef.current
		if (textarea) {
			const { selectionStart, selectionEnd, value } = textarea
			textarea.value = value.substring(0, selectionStart) + "\n" + value.substring(selectionEnd, value.length)
			textarea.selectionStart = textarea.selectionEnd = selectionStart + 1
			textarea.style.height = "auto"
			textarea.style.height = textarea.scrollHeight + "px"
			textarea.scrollTop = textarea.scrollHeight - textarea.clientHeight
			inputMsg(textarea.value)
		}
	}

	return (
		<InputBoxStyled style={{ backgroundColor: token.colorBgContainer }}>
			<AudioRecorderComponent inputMsg={inputMsg} />
			<Dropdown
				menu={{ items: matchItems, style: { width: "30%" }, onClick: questionClick }}
				placement="top"
				open={(matchItems && matchItems.length > 0) || false}
			>
				<textarea
					onKeyPress={(e) => enterHandle(e)}
					ref={textareaRef}
					value={msg}
					onChange={changeHandle}
					rows={1}
					placeholder={"Shift+Enter添加换行"}
					style={{ backgroundColor: token.colorBgContainer, color: token.colorText }}
				/>
			</Dropdown>
			{(model?.mediaCount || 0) > 0 && (
				<FileUpload fileArr={fileArr} resetFileArr={resetFileArr} addFile={addFile} />
			)}
			<IconButton onClick={sendHandle} loading={loading}>
				<SendOutlined />
			</IconButton>
		</InputBoxStyled>
	)
}

const FileList = ({
	fileArr,
	resetFileArr,
}: {
	fileArr: UploadFile[]
	resetFileArr: (files: UploadFile[]) => void
}) => {
	const { Paragraph } = Typography
	return (
		<>
			{fileArr.map((file, index) => {
				return (
					<Col key={index}>
						<Paragraph
							code
							copyable={{
								// eslint-disable-next-line react/jsx-key
								icon: [<DeleteOutlined />, <DeleteOutlined />],
								onCopy: () => {
									resetFileArr(fileArr.filter((_, i) => i !== index))
								},
								tooltips: false,
							}}
						>
							{file.file.name}
						</Paragraph>
					</Col>
				)
			})}
		</>
	)
}

function FileUpload({
	fileArr,
	resetFileArr,
	addFile,
}: {
	fileArr: UploadFile[]
	resetFileArr: (files: UploadFile[]) => void
	addFile: (file: UploadFile) => void
}) {
	const uploadFile = (file: RcFile) => {
		return UploadKimi(file).then((res) => {
			addFile({ file, content: res.data.content })
			return ""
		})
	}

	return (
		<Popover open={fileArr.length > 0} content={<FileList fileArr={fileArr} resetFileArr={resetFileArr} />}>
			<Upload multiple maxCount={5} action={uploadFile} fileList={[]}>
				<IconButton icon={<UploadOutlined />}></IconButton>
			</Upload>
		</Popover>
	)
}

function AudioRecorderComponent({ inputMsg }: { inputMsg: (value: string) => void }) {
	const { mediaBlobs, mediaRecorder, startRecord, stopRecord, composeRecord } = useMediaRecorder()
	const [recording, setRecording] = useState(false)
	const mediaUrl = useRef("")
	const { token } = theme.useToken()

	const resetInput = () => {
		if (mediaBlobs.current.length > 0) {
			const combinedBlob = new Blob(mediaBlobs.current, { type: "audio/wav" })
			getWaveBlob(combinedBlob, false, { sampleRate: 16000 }).then((value) => {
				mediaUrl.current = URL.createObjectURL(value)
				fetch(mediaUrl.current).then((response) => {
					response.blob().then((audioBlob) => {
						convertBlobToBase64(audioBlob).then((base64Data) => {
							const d = base64Data as string
							if (d.length > 10) {
								audioToText(d.replace(/^data:.+;base64,/, "")).then((data) => {
									inputMsg(data.data.text)
								})
							}
						})
					})
				})
			})
		}
	}

	// const handlePlay = () => {
	//     if (mediaUrl.current) {
	//         const audioElement = new Audio();
	//         audioElement.src = mediaUrl.current;
	//         audioElement.play();
	//     }
	// }

	const convertBlobToBase64 = (blob: Blob) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()

			reader.onloadend = () => {
				const base64Data = reader.result
				resolve(base64Data)
			}

			reader.onerror = () => {
				reject(new Error("Failed to convert Blob to base64."))
			}
			reader.readAsDataURL(blob)
		})
	}

	const startRecording = () => {
		startRecord()
	}

	const stopRecording = () => {
		stopRecord()
		if (mediaRecorder.current) {
			mediaRecorder.current.addEventListener("stop", () => {
				resetInput()
				URL.revokeObjectURL(mediaUrl.current)
				composeRecord()
			})
		}
		// handlePlay()
	}

	const recordingHandle = () => {
		setRecording(!recording)
	}

	useEffect(() => {
		if (recording) {
			startRecording()
		} else {
			stopRecording()
		}
	}, [recording])

	return (
		<Tooltip title={recording ? "点击停止" : "点击录音"}>
			<IconButton onClick={recordingHandle} style={{ padding: 0 }} shape="circle">
				{recording ? <RecordingStyled style={{ backgroundColor: token.colorPrimary }}></RecordingStyled> : null}
				<AudioOutlined
					style={{ position: "absolute", zIndex: 2, top: "28%", left: "28%", borderRadius: "50%" }}
				/>
			</IconButton>
		</Tooltip>
	)
}

const RecordingStyled = styled.div`
	@keyframes ripple-animation {
		0% {
			transform: scale(0.6);
			opacity: 1;
		}
		50% {
			transform: scale(1);
			opacity: 0.5;
		}
		100% {
			transform: scale(0.6);
			opacity: 1;
		}
	}

	width: 100%;
	height: 100%;
	top: 0;
	position: absolute;
	border-radius: 50%;
	transform: scale(0);
	animation: ripple-animation 1.8s linear infinite;
	pointer-events: none; // 防止水波纹影响按钮的交互
	z-index: 1;
`

const InputBoxStyled = styled.div`
	width: 80%;
	min-height: 5%;
	max-height: 18%;
	background-color: white;
	border: 0.1px solid #ccc;
	border-radius: 2rem;
	display: flex;
	margin-top: 1rem;
	padding: 0.5% 2% 0.5% 3%;
	align-items: center;
	box-sizing: border-box;

	textarea {
		resize: none;
		border: none;
		outline: none;
		overflow: auto;
		max-height: 100%;
		width: 90%;
		margin-left: 0.5em;
		line-height: 2.4rem;
		//font-size: var(--primary-font-size);
	}

	textarea::placeholder {
		font-size: 1.4rem;
	}
`
