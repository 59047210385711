import React from "react"
import { ReactNode } from "react"
import { Avatar, Card, List, Space } from "antd"
import { useAuth } from "../../context/auth-context"
import Icon from "@ant-design/icons"
import { ReactComponent as NO } from "assets/icon/no.svg"
import { ReactComponent as OK } from "assets/icon/ok.svg"
import { Content } from "antd/es/layout/layout"
import { AvatarModal, ChangeMobileModal, DelAccount, PasswordModal, UsernameModal, WeChatModal } from "./content-modal"

type ListData = {
	avatar: string
	title: string
	description: ReactNode
	actions: Array<ReactNode>
}

export default function AccountContent() {
	const { user } = useAuth()

	const loginData = {
		phone: null,
		username: null,
		email: null,
		weChatBound: false,
	} as {
		phone: null | string
		username: null | string
		email: null | string
		weChatBound: boolean
	}

	user?.login.map((item) => {
		if (item.type == 1) {
			loginData.username = item.account
		} else if (item.type == 2) {
			loginData.phone = item.account
		} else if (item.type == 3) {
			loginData.weChatBound = true
		}
	})

	const userInfoData = [
		{
			avatar: user?.avatar,
			title: "头像",
			description: "支持2M以内的图片",
			actions: [<AvatarModal key={0} />],
		},
		{
			avatar: null,
			title: "用户名",
			description: user?.username,
			actions: [<UsernameModal key={1} />],
		},
		{
			avatar: null,
			title: "手机",
			description: (
				<Space>
					<Icon component={loginData.phone ? OK : NO} />
					{loginData.phone ? loginData.phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") : "未绑定"}
				</Space>
			),
			actions: [<ChangeMobileModal key={2} mobile={loginData.phone as string} />],
		},
		{
			avatar: null,
			title: "密码",
			description: (
				<Space>
					<Icon component={loginData.username ? OK : NO} />
					{loginData.username ? "已设置" : "未设置"}
				</Space>
			),
			actions: [<PasswordModal isSetup={!!loginData.username} mobile={loginData.phone as string} key={4} />],
		},
		{
			avatar: null,
			title: "微信",
			description: (
				<Space>
					<Icon component={loginData.weChatBound ? OK : NO} />
					{loginData.weChatBound ? "已绑定" : "未绑定"}
				</Space>
			),
			actions: [<WeChatModal isBound={loginData.weChatBound} key={6} />],
		},
	] as Array<ListData>

	const securityData = [
		{
			avatar: null,
			title: "账号注销",
			description: "删除所有数据，永久注销当前账号",
			actions: [<DelAccount key={7} mobile={loginData.phone as string} />],
		},
	] as unknown as Array<ListData>

	return (
		<Content style={{ justifyContent: "center", display: "flex" }}>
			<Card style={{ width: "40%", minWidth: "30rem", border: "none", marginTop: "3%" }}>
				<Space direction={"vertical"} style={{ width: "100%" }}>
					<h1>账号设置</h1>
					<h2 style={{ fontWeight: "normal", margin: "2.5rem 0" }}>基本信息</h2>
					<AccountList data={userInfoData} />
					<h2 style={{ fontWeight: "normal", margin: "2.5rem 0" }}>安全设置</h2>
					<AccountList data={securityData} />
				</Space>
			</Card>
		</Content>
	)
}

function AccountList({ data }: { data: Array<ListData> }) {
	return (
		<List
			dataSource={data}
			renderItem={(item) => {
				return (
					<List.Item actions={item.actions}>
						<List.Item.Meta
							avatar={item.avatar && <Avatar src={item.avatar} />}
							title={item.title}
							description={item.description}
						/>
					</List.Item>
				)
			}}
		/>
	)
}
