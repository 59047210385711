import ReactMarkdown from "react-markdown"
import React, { useEffect, useState } from "react"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw"
import { Route, Routes } from "react-router"
import { Flex } from "antd"

const AgreementsMD = ({ MDModule }: { MDModule: URL }) => {
	const [MDContent, setMDContent] = useState("")

	useEffect(() => {
		fetch(MDModule)
			.then((res) => res.text())
			.then((data) => setMDContent(data))
	}, [])

	return (
		<Flex vertical={true} align={"center"} style={{ width: "100%" }}>
			<Flex vertical style={{ width: "70%", marginTop: "5rem" }} gap={"middle"}>
				<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[[remarkGfm, { tableCellPadding: "10px" }]]}>
					{MDContent}
				</ReactMarkdown>
			</Flex>
		</Flex>
	)
}

const Privacy = () => {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const MD = require("../assets/agreements/隐私保护声明1.md")
	return <AgreementsMD MDModule={MD} />
}

const Behavior = () => {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const MD = require("../assets/agreements/用户协议1.md")
	return <AgreementsMD MDModule={MD} />
}

export default function Agreements() {
	return (
		<Routes>
			<Route path={"privacy"} element={<Privacy />} />
			<Route path={"behavior"} element={<Behavior />} />
		</Routes>
	)
}
