import React from "react"
import { Route, Routes } from "react-router"
import { BrowserRouter, Navigate } from "react-router-dom"
import Home from "./home"
import { Login } from "./login"
import { useAuth } from "../context/auth-context"
import AccountSetup from "./account-setup"
import Agreements from "./agreements"

function CommonRouter() {
	return (
		<Routes>
			<Route path={"/login"} element={<Login />} />
			<Route path="/agreements/*" element={<Agreements />} />
			<Route path="/*" element={<Navigate to="/" />} />
		</Routes>
	)
}

function UnAuthenticatedRouter() {
	return (
		<Routes>
			<Route index element={<Login />} />
			<Route path="/*" element={<CommonRouter />} />
		</Routes>
	)
}

function AuthenticatedRouter() {
	return (
		<Routes>
			<Route path={"/home"} element={<Home />} />
			<Route path={"/account"} element={<AccountSetup />} />
			<Route index element={<Login />} />
			<Route path="/*" element={<CommonRouter />} />
		</Routes>
	)
}

export default function PageRouter() {
	const { user } = useAuth()

	return <BrowserRouter>{user ? <AuthenticatedRouter /> : <UnAuthenticatedRouter />}</BrowserRouter>
}
