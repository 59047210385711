import axios, {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {getToken, isLogout, logout, refresh_token} from "./auth-provider";


export const BaseUrl = process.env.REACT_APP_BASE_URL


const instance = axios.create({
    baseURL: BaseUrl,
    timeout: 5000
})

const whiteList = ["/refresh_token", "/username_login", "/code"]
let isRefreshing = false

export const formatToken = (token: string): string => {
    return "Token " + token;
};


instance.interceptors.request.use(
    config => {
        return whiteList.some(v => (<string>config.url).indexOf(v) > -1) ? config :
            new Promise(resolve => {
                const data = getToken()
                if (data) {
                    const now = new Date().getTime()
                    const expired = parseInt(data.expires) - now <= 0;
                    if (expired && !isLogout()) {
                        if (!isRefreshing) {
                            isRefreshing = true
                            refresh_token().then(() => {
                                    config.headers["Authorization"] = formatToken(getToken().accessToken)
                                }
                            ).catch(() => logout()).finally(() => {
                                    isRefreshing = false
                                    resolve(config)
                                }
                            )
                        }
                    } else {
                        config.headers["Authorization"] = formatToken(getToken().accessToken)
                        resolve(config)
                    }
                } else {
                    resolve(config)
                }
            })
    }, error => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response.data
    }, error => {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    toast.error("未知错误")
                    break
                case 401:
                    logout().then(() =>
                        toast.error("请重新登录")
                    )
                    break
                case 421:
                    toast.warning(error.response.data.msg)
                    break
                default:
                    return Promise.reject(error)
            }
        } else {
            toast.error("网络异常")
        }
        return Promise.reject(error)
    }
)

export default instance
