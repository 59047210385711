import { useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const useGToken = (action: string) => {
	const { executeRecaptcha } = useGoogleReCaptcha()
	const [gToken, setGToken] = useState<string>("")
	const handleReCaptchaVerify = () => {
		if (!executeRecaptcha) {
			// console.error('gToken', '执行验证码尚不可用');
			return
		}
		return executeRecaptcha(action).then((token) => {
			setGToken(token)
			return token
		})
	}

	// useEffect(() => {
	// 	console.log(111111)
	// 	handleReCaptchaVerify()
	// }, [])
	return { gToken, handleReCaptchaVerify }
}

export default useGToken
