import { PDForm, Protocol, StyledForm } from "./component"
import React from "react"
import { Button, Card } from "antd"
import { LoginPageType } from "../../pages/login"
import { RegisterForm } from "../../types/user"
import { useAuth } from "../../context/auth-context"

export default function Register({ setPageType }: { setPageType: (b: LoginPageType) => void }) {
	const { register } = useAuth()
	const handleSubmit = async (values: unknown): Promise<void> => {
		await register(values as RegisterForm)
	}

	return (
		<Card bordered={false} style={{ paddingTop: "2rem", boxShadow: "none" }}>
			<StyledForm
				onFinish={handleSubmit}
				submitter={{
					render: (props) => {
						return [
							<Button
								key="submit"
								type={"primary"}
								size={"large"}
								style={{ width: "100%" }}
								onClick={() => {
									/* eslint-disable-next-line react/prop-types */
									return props.submit()
								}}
							>
								注册
							</Button>,
						]
					},
				}}
			>
				<PDForm source={2} />
				<Protocol />
			</StyledForm>
			<Button style={{ width: "100%", marginTop: "1rem" }} onClick={() => setPageType(1)}>
				返回
			</Button>
		</Card>
	)
}
