import React from "react"
import {Avatar, Divider, Dropdown, Image, Row, theme} from "antd";
import Lg from "assets/logo.png"
import {Header} from "antd/es/layout/layout";
import { LogoutOutlined } from "@ant-design/icons";
import {useAuth} from "../../context/auth-context";
import { Link } from "react-router-dom";


export default function Nav() {
    const { token } = theme.useToken()

    return <Header style={{ backgroundColor: token.colorBgLayout }}>
        <Row justify={"space-between"} align={"middle"}>
            <Logo/>
            <UserMenu/>
        </Row>
    </Header>
}

function Logo() {
    return <Image width={"8%"} src={Lg} preview={false}/>
}

function UserMenu() {
    const {user, logout} = useAuth()

    const handleMenuClick = ({key}: {key: string}) => {
        if (key == "logout") {
            logout().then()
        }
    }
    const items = [
        {
            key: "username",
            label: <>{user?.username}<Divider style={{margin: 0}}/></>
        },
        {
            key: "accountSetup",
            label: <Link to="/account" target="_blank" rel="noopener noreferrer">账号设置</Link>
        },
        {
            key: "logout",
            icon: <LogoutOutlined />,
            label: <>{"登出"}</>
        },
    ]

    return <Dropdown menu={{items, onClick: handleMenuClick}} >
            <Avatar src={user?.avatar} alt={"U"} size={"default"} style={{maxWidth: "8%", height: "auto"}}></Avatar>
    </Dropdown>
}
