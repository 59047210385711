export interface User {
	token_data: {
		access_token: string
		refresh_token: string
		expires: string
	}
	user_info: {
		id: number
		username: string
		avatar: string
		login: Array<{
			account: string
			type: LoginType
		}>
	}
}

export type AuthForm = { username: string; password: string } | { mobile: string; code: string } | WeChatBindForm

export type RegisterForm = {
	mobile: string
	code: string
	password: string
}

export type WeChatBindForm = {
	track_id: string
	phone?: string
	code?: string
}

// 账号1 手机号2
export type LoginType = 1 | 2

export interface UserUpdate {
	username?: string
	avatar?: string
}

export type ResetPasswordForm = {
	mobile: string
	code: string
	password: string
}

export enum SceneType {
	Oauth = "1", // 登录
	Bind = "2", // 绑定微信
	UnBind = "3", // 解绑微信
}

export type WeChatQR = {
	url: string
	expire_seconds: number
	ticket: string
}

export enum ScanType {
	UnFollow = "1", // 未关注
	Followed = "2", // 已关注
}

export type weChatLoginQRStateRes = {
	status: ScanType
	is_bound: boolean
}
