import { v4 as uuid } from "uuid"
import { switchModel } from "../components/chat/SwitchModel"
import { User } from "./user"
import { InputMsg } from "../components/chat/ChatBox"

export interface Visitor {
	id: string
	username: string
	avatar: string
}

interface BaseMsgData {
	askId: string
	createOn: string
	index?: number
}

interface MsgData extends BaseMsgData {
	content: string
}

interface OcrMsgData extends MsgData {
	ocr: { filename: string }[]
}

export enum MsgContentType {
	Text = "text",
	MarkDown = "markDown",
	OCR = "ocr",
}

export interface MsgContent {
	msgType: MsgContentType.Text | MsgContentType.MarkDown
	data: MsgData
	error?: ChatError
}

export interface OcrMsgContent {
	msgType: MsgContentType.OCR
	data: OcrMsgData
	error?: ChatError
}

export interface MessagePayload {
	user: User["user_info"] | Visitor | null
	msgContent: MsgContent | OcrMsgContent
}

export interface Message {
	type: "A" | "R"
	payload: MessagePayload
}

interface ChatError {
	code: string
	message: string
}

export const MsgType = {
	Ask: "A", // 问
	Answer: "R", // 答
}

export interface AskData extends MsgData {
	userId: string | number
	type: string
	model: switchModel
	history: {
		role: "user" | "assistant"
		content: string
	}
}

export function getDefaultSendMessage(content: InputMsg) {
	let data = {
		createOn: Date().toString(),
		content: content.content,
		askId: uuid(),
	} as object
	if (content.type == MsgContentType.OCR) {
		data = { ...data, ocr: content.ocr }
	}
	return {
		type: MsgType.Ask,
		payload: {
			user: null,
			msgContent: {
				msgType: content.type,
				data: data,
			},
		},
	}
}

export function getDefaultReplyMsg(askId: string) {
	return {
		type: MsgType.Answer,
		payload: {
			user: null,
			msgContent: {
				msgType: "text",
				data: {
					createOn: Date().toString(),
					content: "",
					askId: askId,
				},
			},
		},
	}
}

export function msgListToApiMsg(msgList: Array<Message>) {
	return msgList.map((item) => ({
		role: item.type == MsgType.Ask ? "user" : "assistant",
		content: item.payload.msgContent.data.content,
	}))
}
