import Cookies from "js-cookie"

import { AuthForm, RegisterForm, User } from "../types/user"
import { accountLogin, mobileLogin, refreshToken, userInfo, userRegister, weChatLogin } from "../service/user"

const cookiesDomain = process.env.REACT_APP_DOMAIN

export const sessionKey = "user-info"
export const TokenKey = "authorized-token"
export const userStatusKey = "userState"

type userCookieStatus = "1" | "0"

export const setUserStatus = (status: userCookieStatus) => {
	Cookies.set(userStatusKey, status, { domain: cookiesDomain, path: "/" })
}

export const setLogout = () => {
	setUserStatus("0")
}

export const setLogin = () => {
	setUserStatus("1")
}

export const isLogout = () => {
	return Cookies.get(userStatusKey) == "0"
}

interface UserInfo {
	id: number
	username: string
	expires: string | number
	refreshToken: string
}

export const setToken = ({ user }: { user: User }) => {
	const expiresDate = !isNaN(Number(user.token_data.expires))
		? new Date(Number(user.token_data.expires))
		: new Date(user.token_data.expires)
	const cookieString = JSON.stringify({
		accessToken: user.token_data.access_token,
		expires: expiresDate.getTime(),
	})

	if (expiresDate.getTime() > 0) {
		Cookies.set(TokenKey, cookieString, {
			expires: (expiresDate.getTime() - Date.now()) / 86400000,
			domain: cookiesDomain,
			path: "/",
		})
	} else {
		Cookies.set(TokenKey, cookieString, { domain: cookiesDomain, path: "/" })
	}

	window.localStorage.setItem(
		sessionKey,
		JSON.stringify({
			id: user.user_info.id,
			username: user.user_info.username,
			refreshToken: user.token_data.refresh_token,
			expires: expiresDate.getTime(),
		} as UserInfo),
	)
	setLogin()
}

export const removeToken = () => {
	Cookies.remove(TokenKey, { domain: cookiesDomain, path: "/" })
	window.localStorage.removeItem(sessionKey)
	setLogout()
}

export const getToken = () => {
	return Cookies.get(TokenKey)
		? JSON.parse(<string>Cookies.get(TokenKey))
		: JSON.parse(<string>window.localStorage.getItem(sessionKey))
}

export const getLocalUserInfo = () => {
	return JSON.parse(<string>window.localStorage.getItem(sessionKey))
}

export const login = (data: AuthForm) => {
	let api
	if ("track_id" in data) {
		api = weChatLogin(data)
	} else if ("mobile" in data) {
		api = mobileLogin(data)
	} else {
		api = accountLogin(data)
	}
	return api.then((res) => {
		const user = res.data
		setToken({ user })
		return user.user_info
	})
}

export const register = (data: RegisterForm) => {
	return userRegister(data).then((res) => {
		const user = res.data
		setToken({ user })
		return user.user_info
	})
}

export const logout = async () => {
	removeToken()
}

export const refresh_token = () => {
	const data = getToken()
	return refreshToken(data.refreshToken).then((res) => {
		const user = res.data
		setToken({ user })
		return user.user_info
	})
}

export const user_info = () => {
	return userInfo().then((res) => {
		const user = res.data
		setToken({ user })
		return user
	})
}
