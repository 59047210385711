import {ThemeConfig} from "antd/es/config-provider/context";
import {DarkTheme, LightTheme} from "./theme-context";


export type Action = {type: "change_dark", payload?: unknown} | {type: "change_light", payload?: unknown}
interface CustomTheme {
    codeStyle?: string
}
export interface CustomThemeConfig {
    antd: ThemeConfig;
    custom: CustomTheme
}

export default function ThemeReducer(themeState: CustomThemeConfig, action: Action) {
    switch (action.type) {
        case 'change_dark':
            return DarkTheme
        case 'change_light':
            return LightTheme
    }
}
