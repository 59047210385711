import {useEffect, useRef, useState} from "react";

export const wsReadyState = {
    Connecting: 0,
    Ready: 1,
    Closing: 2,
    Closed: 3
}

export default function useWS(url: string, protocol: string) {
    const ws = useRef<WebSocket | null>(null)
    const [wsMessage, setWsMessage] = useState<string[]>([])
    const [wsState, setState] = useState<number>(wsReadyState.Connecting)

    const createWS = () => {
        ws.current = new WebSocket(url, [protocol])
        ws.current.onopen = () => {
            setState(ws.current?.readyState ?? wsReadyState.Connecting)
        }
        ws.current.onclose = () => {
            setState(ws.current?.readyState ?? wsReadyState.Connecting)
        }
        ws.current.onerror = () => {
            setState(ws.current?.readyState ?? wsReadyState.Connecting)
        }
        ws.current.onmessage = (e) => {
            setWsMessage(m => {
                return [...m, e.data];
            })
        }
    }

    const sendMsgWS = (data: string | ArrayBufferLike | Blob | ArrayBufferView) => {
        ws.current?.send(data)
    }

    const initWS = () => {
        if (!ws.current || ws.current?.readyState === wsReadyState.Closed) {
            createWS()
        }
    }

    const closeWS = () => {
        ws.current?.close()
    }

    const reconnectWS = () => {
        closeWS()
        createWS()
    }

    useEffect(() => {
        initWS()
        return () => {
            closeWS()
        }
    }, [url])

    return {
        wsMessage,
        wsState,
        sendMsgWS,
        closeWS,
        reconnectWS,
        setWsMessage
    }
}

