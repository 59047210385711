import React from 'react';
import "./App.css";
import PageRouter from "./pages/router";
import {FullPageLoading, FullPageErrorFallback} from "./components/FullPage";
import {ErrorBoundary} from "./components/ErrorBoundary";
import {ToastContainer} from "react-toastify";
// import {ErrorBoundary} from "react-error-boundary";
import "react-toastify/ReactToastify.min.css";
import {theme} from "antd";


function App() {
    const {token} = theme.useToken()

    return (
        <div className="App" style={{backgroundColor: token.colorBgBase, color: token.colorText}}>
            <ErrorBoundary fallbackRender={FullPageErrorFallback}>
                <React.Suspense fallback={<FullPageLoading/>}>
                    <ToastContainer theme="colored" autoClose={3000} newestOnTop={true}/>
                    <PageRouter/>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
}

export default App;


