import React from "react"
import AccountHeader from "../components/account-setup/header";
import {Layout, theme} from "antd";
import AccountContent from "../components/account-setup/content";


export default function AccountSetup() {
    const {token} = theme.useToken()

    return <Layout style={{backgroundColor: token.colorBgContainer}}>
        <AccountHeader/>
        <AccountContent/>
    </Layout>
}
