import { Message, MsgContent, MsgContentType, OcrMsgContent } from "../../types/chat"
import { RecordRes } from "../../service/chat"
import { switchModel } from "./SwitchModel"

export const ChatRecordToMessage = (res: Array<RecordRes>) => {
	const result = res.map((item) => {
		let msg = {
			msgType: MsgContentType.MarkDown,
			data: {
				createOn: item.created_at,
				content: item.content,
				askId: item.msg_id,
			},
		} as MsgContent | OcrMsgContent

		if (item.type == "A" && item.model_type == switchModel.Kimi && item.content_expand) {
			msg = {
				msgType: MsgContentType.OCR,
				data: {
					createOn: item.created_at,
					content: item.content,
					askId: item.msg_id,
					ocr: JSON.parse(item.content_expand),
				},
			}
		}
		return {
			type: item.type,
			payload: {
				user: null,
				msgContent: msg,
			},
		}
	}) as Array<Message>
	return result
}
