import { Avatar, Select, Space } from "antd"
import React from "react"
import Forum from "assets/model/forum.svg"
import LightbulbCircle from "assets/model/lightbulbCircle.svg"
import DocumentScanner from "assets/model/documentScanner.svg"
// import WZ from "assets/WZ.JPG"

// export type switchModel = "ChatGPT" | "WZ" | "XH" | "XH_V2" | "XH_V3" | "Kimi"

export enum switchModel {
	ChatGPT = "ChatGPT",
	WZ = "WZ",
	XH = "XH",
	XH_V2 = "XH_V2",
	XH_V3 = "XH_V3",
	Kimi = "Kimi",
}

export type OptionType = {
	value: switchModel
	label: string
	logo: string
	acceptMediaType: string
	mediaCount: number
}

export const options: Array<OptionType> = [
	// {value: "ChatGPT", label: "ChatGPT", logo: ChatGpt},
	// { value: "WZ", label: "WZ", logo: WZ },
	{ value: switchModel.XH, label: "对话", logo: Forum, acceptMediaType: "", mediaCount: 0 },
	{ value: switchModel.XH_V2, label: "写作", logo: LightbulbCircle, acceptMediaType: "", mediaCount: 0 },
	{ value: switchModel.Kimi, label: "文档", logo: DocumentScanner, acceptMediaType: ".pdf,.doc", mediaCount: 1 },
	// { value: "XH_V3", label: "星火 v3", logo: XH },
]

const Option = ({ option }: { option: OptionType }) => {
	return (
		<Space>
			<Avatar size={"small"} src={option.logo} />
			<strong>{option.label}</strong>
		</Space>
	)
}

export type SwitchModelProps = {
	model: switchModel
	changeModel: (model: switchModel) => void
}

export default function SwitchModel({ model, changeModel }: SwitchModelProps) {
	return (
		<Select defaultValue={model} bordered={false} style={{ width: "14rem" }} onChange={changeModel}>
			{options.map((item) => (
				<Select.Option key={item.value} value={item.value}>
					<Option option={item} />
				</Select.Option>
			))}
		</Select>
	)
}
