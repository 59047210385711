import instance from "../utils/http"
import { ApiResponse } from "./util"
import {
	AuthForm,
	RegisterForm,
	ResetPasswordForm,
	SceneType,
	User,
	UserUpdate,
	weChatLoginQRStateRes,
	WeChatQR,
} from "../types/user"

export const accountLogin = (data: AuthForm): Promise<ApiResponse<User>> => {
	return instance.post("v1/user/username_login/", data)
}

export const getUserInfo = (): Promise<ApiResponse<User["user_info"]>> => {
	return instance.get("v1/user/me/")
}

export const refreshToken = (refreshToken: string): Promise<ApiResponse<User>> => {
	return instance.post("v1/user/refresh_token/", {
		refreshToken: refreshToken,
	})
}

export const userInfo = (): Promise<ApiResponse<User>> => {
	return instance.post("v1/user/user_info/")
}

export const sendCode = (phone: string, source: number, gToken: string): Promise<ApiResponse<object>> => {
	return instance.post("v1/code/", { phone: phone, source: source }, { headers: { gToken } })
}

export const mobileLogin = (data: AuthForm): Promise<ApiResponse<User>> => {
	return instance.post("v1/user/mobile_login/", data)
}

export const userUpdate = (u_id: number, data: UserUpdate): Promise<ApiResponse<User["user_info"]>> => {
	return instance.patch(`v1/user/${u_id}/`, data)
}

export const userRegister = (data: RegisterForm): Promise<ApiResponse<User>> => {
	return instance.post("v1/user/register/", data)
}

export const resetPassword = (data: ResetPasswordForm): Promise<ApiResponse<User>> => {
	return instance.post("v1/user/reset_password/", data)
}

export const weChatLogin = (data: AuthForm): Promise<ApiResponse<User>> => {
	return instance.post("v1/user/wechat_login/", data)
}

export const weChatLoginQR = (track_id: string, type = SceneType.Oauth): Promise<ApiResponse<WeChatQR>> => {
	return instance.post("v1/user/mp_qr/", { track_id, type })
}

export const weChatLoginQRState = (
	track_id: string,
	type = SceneType.Oauth,
): Promise<ApiResponse<weChatLoginQRStateRes>> => {
	return instance.post("v1/user/mp_qr_state/", { track_id, type })
}

export const ChangeMobile = (data: object) => {
	return instance.post("v1/user/change_mobile/", data)
}

export const DelAccountApi = (data: object) => {
	return instance.post("v1/user/del_account/", data)
}
