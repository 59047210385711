import React, { useEffect, useRef, useState } from "react"
import { Button, Input, Row, Space, Tabs, theme, Typography } from "antd"
import { LoginForm, PageContainer, ProForm } from "@ant-design/pro-components"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import { AuthForm, ScanType } from "../../types/user"
import { useAuth } from "../../context/auth-context"
import { Protocol, SendMobileCode } from "./component"
import { LoginPageType } from "../../pages/login"
import { v4 } from "uuid"
import { weChatLoginQR, weChatLoginQRState } from "../../service/user"
import { toast } from "react-toastify"
import QRCode from "qrcode.react"

type LoginType = "mobile" | "account" | "weChat"

export default function LoginComponent({ setPageType }: { setPageType: (b: LoginPageType) => void }) {
	const { login } = useAuth()
	const { token } = theme.useToken()
	const [loginType, setLoginType] = useState<LoginType>("mobile")
	const { Link } = Typography
	const handleSubmit = async (values: unknown): Promise<void> => {
		await login(values as AuthForm)
	}

	return (
		<Space direction={"vertical"}>
			<Tabs
				centered
				activeKey={loginType}
				onChange={(activeKey) => setLoginType(activeKey as LoginType)}
				items={[
					{ key: "mobile", label: "手机号登录" },
					{ key: "account", label: "账号登录" },
					{ key: "weChat", label: "微信登录" },
				]}
			/>
			{loginType !== "weChat" && (
				<LoginForm
					onFinish={handleSubmit}
					actions={
						loginType == "account" && (
							<Row justify={"space-between"}>
								<Link style={{ color: token.colorPrimary }} onClick={() => setPageType(3)}>
									忘记密码
								</Link>
								<Link style={{ color: token.colorPrimary }} onClick={() => setPageType(2)}>
									去注册
								</Link>
							</Row>
						)
					}
				>
					{loginType == "account" && (
						<>
							<ProForm.Item name={"username"} rules={[{ required: true, message: "请输入用户名" }]}>
								<Input
									allowClear={true}
									maxLength={15}
									placeholder={"用户名"}
									size={"large"}
									prefix={<UserOutlined className={"prefixIcon"} />}
								></Input>
							</ProForm.Item>
							<ProForm.Item name={"password"} rules={[{ required: true, message: "请输入密码" }]}>
								<Input.Password
									maxLength={15}
									placeholder={"密码"}
									size={"large"}
									prefix={<LockOutlined className={"prefixIcon"} />}
								></Input.Password>
							</ProForm.Item>
						</>
					)}
					{loginType === "mobile" && <SendMobileCode source={1} />}
					<Protocol />
				</LoginForm>
			)}
			<PageContainer>{loginType === "weChat" && <WeChatLogin />}</PageContainer>
		</Space>
	)
}

const WeChatLogin = () => {
	const [qr, setQr] = useState("")
	const { user, login } = useAuth()
	const track_id = useRef(v4())
	const [isAdopt, setIsAdopt] = useState(false)

	useEffect(() => {
		weChatLoginQR(track_id.current).then((res) => {
			setQr(res.data.url)
		})
	}, [])

	useEffect(() => {
		const interval_id = setInterval(() => {
			if (!user && qr !== "") {
				weChatLoginQRState(track_id.current).then((res) => {
					if (res.data.status == ScanType.Followed) {
						if (res.data.is_bound) {
							login({ track_id: track_id.current }).then(() => {
								toast.success("登录成功")
							})
						} else {
							setIsAdopt(true)
							clearInterval(interval_id)
						}
					}
				})
			}
		}, 1000)
		return () => clearInterval(interval_id)
	}, [qr, user])

	const handleSubmit = async (values: { phone: string; code: string }): Promise<void> => {
		await login({ ...values, track_id: track_id.current })
	}

	return !isAdopt ? (
		<Row justify={"center"}>
			{qr && (
				<QRCode
					value={qr}
					size={128}
					bgColor={"#ffffff"}
					fgColor={"#4e4141"}
					id={"qrcode-canvas"}
					level={"L"}
					// style={{ display: "none" }}
					includeMargin={true}
					// imageSettings={{
					//     src: Logo,
					//     x: undefined,
					//     y: undefined,
					//     height: 28,
					//     width: 28,
					//     excavate: true,
					// }}
				/>
			)}
		</Row>
	) : (
		<ProForm
			onFinish={handleSubmit}
			submitter={{
				render: (props) => {
					return [
						<Button
							key="submit"
							type={"primary"}
							size={"large"}
							style={{ width: "100%" }}
							/* eslint-disable-next-line react/prop-types */
							onClick={() => props.form?.submit?.()}
						>
							绑定提交
						</Button>,
					]
				},
			}}
		>
			<SendMobileCode source={4} />
		</ProForm>
	)
}

// <LoginForm
//     submitter={{searchConfig: {submitText: (loginType == "mobile" ? "登录" : "登录")}}}
//     logo={<Image src={LOGO} width={"100%"}/>}
//     // title="问知科技"
//     subTitle="大模型对话系统"
//     onFinish={handleSubmit}
//     // actions={<Flex><Button type={"primary"} size={"large"} style={{width: "100%"}}>注册</Button></Flex>}
// >
// </LoginForm>
