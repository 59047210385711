import React, { useEffect, useRef, useState } from "react"
import { Card, Divider, List, Modal, Space, Spin, theme, Tooltip, Col } from "antd"
import SwitchModel, { SwitchModelProps } from "./SwitchModel"
import { ClearOutlined, HistoryOutlined, LinkOutlined } from "@ant-design/icons"
import InfiniteScroll from "react-infinite-scroll-component"
import { getChatRecord } from "../../service/chat"
import { ChatRecordToMessage } from "./util"
import { Message, MsgContentType } from "../../types/chat"
import Search from "antd/es/input/Search"

export interface BoxHeader extends SwitchModelProps {
	clearMessageList: () => void
	userId: string | number
}

export const BoxHeader = ({ model, userId, changeModel, clearMessageList }: BoxHeader) => {
	return (
		<Space style={{ width: "100%", padding: "1rem 0 0 0", justifyContent: "space-between" }}>
			<Space style={{ marginLeft: "2rem" }}>
				<SwitchModel model={model} changeModel={changeModel} />
			</Space>
			<Space style={{ marginRight: "3rem" }}>
				<Tooltip title={"清空会话"}>
					<ClearOutlined onClick={clearMessageList} style={{ opacity: 0.5 }} />
				</Tooltip>
				<ChatHistoryModal model={model} userId={userId} />
			</Space>
		</Space>
	)
}

type ThrottleFunction = (fn: (...args: unknown[]) => void, ms: number) => (...args: unknown[]) => void

// 节流函数
const throttle: ThrottleFunction = (fn, ms) => {
	let timer: ReturnType<typeof setTimeout> | null

	return (...args) => {
		if (timer) return
		fn(...args)
		timer = setTimeout(() => {
			timer = null
		}, ms)
	}
}

export const ChatHistoryModal = ({ model, userId }: { model: string; userId: string | number }) => {
	const initPage = { page: 1, pageSize: 10, total: 1000 }
	const { token } = theme.useToken()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [data, setData] = useState<Message[]>([])
	const page = useRef(initPage)
	const search = useRef("")
	const scrollPosition = useRef(0)

	const handleModalOpen = () => {
		if (!isModalOpen) {
			loadMoreData()
		}
		setIsModalOpen(!isModalOpen)
	}

	// 初始化数据
	const resetModal = () => {
		setData([])
		page.current = initPage
		scrollPosition.current = 0
		scrollableDiv && (scrollableDiv.scrollTop = 0)
	}

	const handleModalCancel = () => {
		setIsModalOpen(false)
		resetModal()
	}

	const scrollableDiv = document.getElementById("scrollableDiv")

	const loadMoreData = () => {
		const top = scrollableDiv?.scrollTop || 0
		scrollableDiv && (scrollPosition.current = top)
		getChatRecord(model, userId.toString(), page.current.page, false, search.current).then((res) => {
			const result = ChatRecordToMessage(res.data.result).reverse()
			setData((d) => [...result, ...d])
			page.current = { ...page.current, page: page.current.page + 1, total: res.data.total }
		})
	}

	useEffect(() => {
		scrollableDiv && (scrollableDiv.scrollTop = scrollPosition.current)
	}, [data])

	const OnSearch = (value: string) => {
		resetModal()
		search.current = value
		loadMoreData()
	}

	function doNothing() {
		// 什么都不做
	}

	const loadData = throttle(loadMoreData, 1000)

	return (
		<Space>
			<Tooltip title={"历史记录"}>
				<HistoryOutlined style={{ opacity: 0.5 }} onClick={handleModalOpen} />
			</Tooltip>
			<Modal
				title="历史记录"
				style={{ overflow: "hidden" }}
				open={isModalOpen}
				onCancel={handleModalCancel}
				footer={null}
			>
				<Card bordered={false} style={{ boxShadow: "none" }}>
					<Search placeholder="搜索" allowClear onSearch={OnSearch} />
				</Card>

				<div
					id="scrollableDiv"
					style={{
						maxHeight: "40rem",
						overflow: "auto",
						display: "flex",
						flexDirection: "column-reverse",
					}}
					onScroll={() => {
						if (
							scrollableDiv &&
							scrollableDiv.scrollTop != 0 &&
							data.length < page.current.total &&
							scrollableDiv.scrollTop <= scrollableDiv.clientHeight - scrollableDiv.scrollHeight + 1
						) {
							// console.log(scrollableDiv.scrollTop, scrollableDiv.clientHeight, scrollableDiv.scrollHeight)
							loadData()
						}
					}}
				>
					<InfiniteScroll
						loader={
							<Space style={{ height: "4rem", width: "100%", justifyContent: "center" }}>
								<Spin />
							</Space>
						}
						dataLength={data.length}
						next={doNothing}
						hasMore={data.length < page.current.total}
						endMessage={<Divider plain>end</Divider>}
						inverse={true}
						scrollableTarget="scrollableDiv"
						style={{ display: "flex", flexDirection: "column-reverse", overflow: "auto" }}
					>
						<List
							grid={{ column: 1 }}
							dataSource={data}
							renderItem={(item) => (
								<List.Item>
									<Space direction={"vertical"} style={{ width: "100%" }}>
										<Col style={{ color: token.colorTextTertiary }}>
											{item.type == "R" ? model : "YOU"}
										</Col>
										{item.payload.msgContent.msgType == MsgContentType.OCR && (
											<>
												{item.payload.msgContent.data.ocr.map((i, index) => {
													return (
														<List.Item key={index}>
															<LinkOutlined />
															{i.filename}
														</List.Item>
													)
												})}
											</>
										)}
										<Col>{item.payload.msgContent.data.content}</Col>
										<Col style={{ color: token.colorTextTertiary }}>
											{new Date(item.payload.msgContent.data.createOn).toLocaleString()}
										</Col>
									</Space>
								</List.Item>
							)}
						/>
					</InfiniteScroll>
				</div>
			</Modal>
		</Space>
	)
}
