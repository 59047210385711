import React, { useEffect, useState } from "react"
import { Flex, Image, Row, Typography } from "antd"
import ThemeSwitch from "../components/theme-switch"
import { useAuth } from "../context/auth-context"
import { useNavigate } from "react-router"
import LoginComponent from "../components/login/login"
import LOGO from "assets/logo.png"
import Register from "../components/login/register"
import ResetPassword from "../components/login/reset-password"

export type LoginPageType = 1 | 2 | 3 | 4 | 5 | 6 // login register reset_password weChat

const USER_DOMAIN = process.env.REACT_APP_USER_DOMAIN

export const Login = () => {
	const [pageType, setPageType] = useState<LoginPageType>(1)
	const { user } = useAuth()
	const navigate = useNavigate()
	const { Text } = Typography

	const setPageTypeHandle = (b: LoginPageType) => {
		setPageType(b)
	}

	function areDomainsEqual(domain1: string, domain2: string) {
		// 获取主机名并分割为数组
		const parts1 = domain1.split(".")
		const parts2 = domain2.split(".")

		// 获取一级域名
		const topLevelDomain1 = parts1[parts1.length - 1]
		const topLevelDomain2 = parts2[parts2.length - 1]

		// 判断一级域名是否相等
		return topLevelDomain1 === topLevelDomain2
	}

	useEffect(() => {
		// 因用户没有完全拆开 使用此方法临时解决问题
		const domain = USER_DOMAIN?.replace("https://", "").replace("http://", "") || ""
		if (window.location.host !== domain && !user && areDomainsEqual(window.location.host, domain)) {
			let queryString = window.location.search
			if (queryString === "") {
				queryString += "?"
			} else {
				queryString += "&"
			}
			queryString += `redirect=${window.location.href}`
			window.location.href = `${USER_DOMAIN}${window.location.pathname}${queryString}`
		}
	}, [])

	useEffect(() => {
		if (user) {
			const queryParams = new URLSearchParams(window.location.search)
			const redirect = queryParams.get("redirect")
			if (redirect) {
				window.location.replace(redirect)
			} else {
				navigate("/home")
			}
		}
	}, [user])

	return (
		<Flex justify={"center"} align={"center"} vertical flex={1}>
			<Flex align={"center"} vertical>
				<Image preview={false} src={LOGO} width={"12rem"} />
				<Text type={"secondary"} style={{ marginTop: "1rem" }}>
					未知与挑战 探索与突破
				</Text>
			</Flex>
			<Row>
				<FormBody pageType={pageType} setPageType={setPageTypeHandle} />
				<ThemeSwitch />
			</Row>
		</Flex>
	)
}

const FormBody = ({ pageType, setPageType }: { pageType: LoginPageType; setPageType: (b: LoginPageType) => void }) => {
	if (pageType == 1) {
		return <LoginComponent setPageType={setPageType} />
	} else if (pageType == 2) {
		return <Register setPageType={setPageType} />
	} else if (pageType == 3) {
		return <ResetPassword setPageType={setPageType} />
	} else {
		return <></>
	}
}
