import React from "react"
import {useAuth} from "../../context/auth-context";
import {Avatar, Dropdown, Row} from "antd";
import {LogoutOutlined} from "@ant-design/icons";


export default function AccountHeader() {
    return <Row justify={"end"} align={"middle"} style={{height: "8%", padding: "0 1.5%"}}>
        <User/>
    </Row>
}

function User() {
    const { user, logout } = useAuth()

    const handleMenuClick = ({key}: {key: string}) => {
        if (key == "logout") {
            logout().then()
        }
    }

    const items = [
        {
            key: "logout",
            icon: <LogoutOutlined />,
            label: <>{"登出"}</>
        },
    ]

    return <Dropdown menu={{items, onClick: handleMenuClick}}>
        <Avatar src={user?.avatar} alt={"U"} size={"default"} style={{maxWidth: "8%", height: "auto"}}></Avatar>
    </Dropdown>
}
