import { Button, Card, FormInstance, message } from "antd"
import { PDForm, StyledForm } from "./component"
import React from "react"
import { LoginPageType } from "../../pages/login"
import { ResetPasswordForm } from "../../types/user"
import { resetPassword } from "../../service/user"

export const ResetPasswordCard = ({
	submitHandle,
	form,
}: {
	submitHandle: (values: unknown) => Promise<void>
	form?: FormInstance<unknown>
}) => {
	return (
		<StyledForm
			form={form}
			onFinish={submitHandle}
			submitter={{
				render: (props) => {
					return [
						<Button
							key="submit"
							type={"primary"}
							size={"large"}
							style={{ width: "100%" }}
							/* eslint-disable-next-line react/prop-types */
							onClick={() => props.form?.submit?.()}
						>
							提交
						</Button>,
					]
				},
			}}
		>
			<PDForm source={3} />
		</StyledForm>
	)
}

export default function ResetPassword({ setPageType }: { setPageType: (b: LoginPageType) => void }) {
	const handleSubmit = async (values: unknown): Promise<void> => {
		resetPassword(values as ResetPasswordForm).then(() => {
			message.success("重置密码成功！")
			setPageType(1)
		})
	}
	return (
		<Card bordered={false} style={{ paddingTop: "2rem", boxShadow: "none" }}>
			<ResetPasswordCard submitHandle={handleSubmit} />
			<Button style={{ width: "100%", marginTop: "1rem" }} onClick={() => setPageType(1)}>
				返回
			</Button>
		</Card>
	)
}
