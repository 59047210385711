import instance from "../utils/http"
import { ApiResponse, Pagination } from "./util"

export interface audioToTextRes {
	text: string
}

export const audioToText = (content: string): Promise<ApiResponse<audioToTextRes>> => {
	return instance.post(
		"/v1/asr",
		{
			content: content,
			project_id: "1",
			user_id: "1",
			format: "wav",
		},
		{ baseURL: "http://wenzhitek.com/api/" },
	)
}

export interface RecordRes {
	type: string
	msg_id: string
	index: number
	content: string
	created_at: string
	model_type: string
	content_expand: null | string
}

export const getChatRecord = (
	model: string,
	visitor_id: string,
	page = 1,
	cleaned = true,
	search_val = "",
): Promise<ApiResponse<Pagination<RecordRes>>> => {
	return instance.get(
		`/v1/chat_record/?model_type=${model}&cleaned=${Number(
			cleaned,
		)}&page=${page}&search_val=${search_val}&visitor_id=${visitor_id}`,
	)
}

export const clearMsg = (last_id: string): Promise<ApiResponse<object>> => {
	return instance.post("/v1/chat_record/clear_msg/", { last_msg_id: last_id })
}
